import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { UseMutationOptions, UndefinedInitialDataOptions } from '@tanstack/react-query';
import { useCheckAccess } from 'features/accessControl';
import type { ApiResponse } from 'apisauce';
import { t } from '@lingui/macro';
import type { PaginateResponse } from 'types';
import { api, handleRequest } from '../utils';
import { DefaultError } from '@tanstack/query-core';

const noAccessData = {
  Error: t`No Access`,
  Message: t`You do not have write access to the requested page.`,
};
const noAccessPromise = () =>
  Promise.resolve({
    ok: true,
    data: noAccessData,
  } as ApiResponse<typeof noAccessData>);

/**
 * @param url - The url to fetch data from
 * @param key - queryKey forwarded to useQuery
 * @param revalidate - By default we cache data, setting this to true will always refetch the data
 * @param options - Query options forwarded to useQuery
 * @returns a useQuery hook
 */
export function useGet<T>(
  url: string,
  key: string,
  revalidate: boolean = true,
  options: Omit<UndefinedInitialDataOptions<T>, 'queryKey'> = {}
) {
  return useQuery<T>({
    ...options,
    queryKey: [key],
    queryFn: () => handleRequest(api.get(url)),
    ...(revalidate ? { staleTime: 0 } : {}),
  });
}

type PageState = {
  index: number;
  cursors: string[];
};

/**
 * @param url - The url to fetch data from
 * @param key - queryKey array forwarded to useQuery
 * @param revalidate - By default we don't cache data, setting this to false will cache data
 * @param query - object of url query parameters, will be used to build the query string to first page, subsequent pages will only use cursor
 * @param options - Query options forwarded to useQuery
 * @returns an extended useQuery hook with pagination
 */
export function usePagedGet<T>(
  url: string,
  key: string[],
  query: string = '',
  revalidate: boolean = true,
  options: Omit<UndefinedInitialDataOptions<PaginateResponse<T>>, 'queryKey'> = {}
) {
  const [page, setPage] = useState<PageState>({ index: 0, cursors: [''] });
  const pagedQuery = useQuery<PaginateResponse<T>>({
    ...options,
    queryKey: [...key, page.cursors[page.index]],
    queryFn: () =>
      handleRequest(
        api.get(`${url}?${page.cursors[page.index] ? `cursor=${page.cursors[page.index]}` : query}`)
      ),
    ...(revalidate ? { staleTime: 0 } : {}),
  });
  const nextPage = (cursor: string) => {
    setPage(prev => ({ index: prev.index + 1, cursors: [...prev.cursors, cursor] }));
  };
  const previousPage = () => {
    setPage(prev => ({ index: prev.index - 1, cursors: prev.cursors }));
  };
  const changePage = (statePageIndex: number) => {
    if (statePageIndex > page.index) {
      nextPage(pagedQuery.data?.pagination.cursor || '');
    }
    if (statePageIndex < page.index) {
      previousPage();
    }
  };
  return {
    ...pagedQuery,
    data: pagedQuery.data?.data || [],
    pagination: pagedQuery.data?.pagination || { hasMore: false, cursor: '' },
    pageIndex: page.index,
    pageSize: 100,
    nextPage,
    previousPage,
    changePage,
  };
}

/**
 * @param url - The url to post data to
 * @param options - Mutation options forwarded to useMutation
 * @returns a useMutation hook
 */
export function usePost<T>(
  url: string,
  options: UseMutationOptions<unknown, DefaultError, T, unknown> = {}
) {
  const hasAccess = useCheckAccess(true);
  return useMutation<unknown, DefaultError, T, unknown>({
    ...options,
    mutationFn: hasAccess && url ? data => api.post<T>(url, data) : () => noAccessPromise(),
  });
}

/**
 * @param url - The url to post data to
 * @param options - Mutation options forwarded to useMutation
 * @returns a useMutation hook
 */
export function usePut<T>(url: string, options: UseMutationOptions = {}) {
  const hasAccess = useCheckAccess(true);
  return useMutation({
    ...options,
    mutationFn: hasAccess && url ? data => api.put<T>(url, data) : () => noAccessPromise(),
  });
}

/**
 * @param url - The url to post data to
 * @param options - Mutation options forwarded to useMutation
 * @returns a useMutation hook
 */
export const useDelete = (url: string, options: UseMutationOptions = {}) => {
  const hasAccess = useCheckAccess(true);
  return useMutation({
    ...options,
    mutationFn: hasAccess && url ? () => api.delete(url) : () => noAccessPromise(),
  });
};

/**
 * @param get - The url to fetch data from
 * @param key - queryKey forwarded to useQuery
 * @param put - The url to put data to
 * @param post - The url to post data to
 * @param remove - The url to delete data from
 * @param revalidate - By default we cache data, setting this to true will always refetch the data
 * @returns an object of hooks { get, create, update, remove }
 */
export const useApi = (
  get: string,
  key: string,
  put: string = '',
  post: string = '',
  remove: string = '',
  revalidate: boolean = false
) => {
  return {
    get: useGet(get, key, revalidate),
    create: usePut(put),
    update: usePost(post),
    remove: useDelete(remove),
  };
};
